import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import Link from 'components/base-ui/link';
import { Small } from 'components/base-ui/typo';

export const StContainer = styled.div<{ haveButton?: boolean }>`
  display: grid;
  row-gap: 24px;
  color: ${COLORS.gray3};
  grid-template-rows: 366px auto;
  z-index: 1;
  background-color: ${COLORS.white};

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    grid-template-columns: 100%;
    grid-template-rows: 382px auto;
    transition: visibility 350ms;
    border-radius: 10px;
    padding: 8px;
    margin-bottom: ${(props) => (props.haveButton ? '-48px' : 'unset')};

    :hover {
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
      z-index: 999;

      button {
        visibility: visible;
        height: 48px;
      }

      div {
        box-shadow: none;
      }
    }
  }
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35));
  cursor: pointer;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    filter: unset;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  }
`;

export const StImage = styled.img`
  top: 50%;
  left: 50%;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionIcon = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

export const ActionIconClickable = styled(ActionIcon)`
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export const ActionText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${COLORS.gray4};
  margin: 0 10px;
  cursor: default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
`;

export const StDate = styled(ActionText)`
  margin: 0;
  height: unset;
`;

export const ActionsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Link)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: 66px;
  position: relative;
  width: 100%;
  margin-bottom: 13px;
  color: inherit;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  transition: all 0.2s ease-out;

  &:hover,
  &:focus,
  &:active {
    color: ${COLORS.primary};
  }
`;

export const Date = styled(Small)`
  margin-left: 10px;
`;

export const StButton = styled.button`
  display: block;
  width: 100%;
  height: 0;
  visibility: hidden;
  margin-top: 8px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(105deg, ${COLORS.primary}, ${COLORS.primary});
  color: ${COLORS.white};
  cursor: pointer;
  font-size: 14px !important;
  text-transform: uppercase !important;
  :hover {
    background: ${COLORS.linearGradient};
  }
`;

export const StButtonWrapper = styled.div`
  height: 48px;
`;
